import { useState } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, IconButton, Button, Toolbar, Menu, MenuItem } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { useNavigate } from 'react-router-dom'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

export interface ListItemType {
  label: string
  subItems: {
    label: string
    pathname: string
    state?: any
  }[]
}

export interface AuthenticatedHeaderProps {
  email: string
  items: ListItemType[]
  onSignOut: () => void
}

export const AuthenticatedHeader = ({ items, email, onSignOut }: AuthenticatedHeaderProps) => {
  console.log(email)
  const theme = useTheme()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openList, setOpenList] = useState(new Array(items.length).fill(false) as Array<boolean>)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleNestedListOpen = (index: number) => {
    openList[index] = !openList[index]
    setOpenList([...openList])
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton type="button" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          <div
            style={{
              margin: 0,
              fontWeight: 500,
              fontSize: 24,
              lineHeight: 1.6,
            }}
          ></div>
          <Box style={{ flexGrow: 1 }} />
          <Box style={{ display: 'flex' }}>
            <Button type="button" onClick={handleMenuClick}>
              <span style={{ color: 'white' }}>{email}</span>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose} style={{}} variant={'menu'}>
        <MenuItem onClick={onSignOut}>ログアウト</MenuItem>
      </Menu>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ marginTop: -9 }}>
          {items.map((item, index) => (
            <div key={index}>
              <ListItem disablePadding>
                <ListItemButton onClick={() => handleNestedListOpen(index)}>
                  <ListItemText primary={item.label} />
                  {openList[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              {item.subItems.map((subItem, idx) => (
                <Collapse key={idx} in={openList[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleDrawerClose()
                        navigate(subItem.pathname, { state: { ...subItem?.state } })
                      }}
                    >
                      <ListItemText primary={subItem.label} />
                    </ListItemButton>
                  </List>
                </Collapse>
              ))}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}
