import * as React from 'react'
import { ENV } from 'config'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from 'libs/tanStackQuery'
import { ErrorBoundary } from 'react-error-boundary'
import { HelmetProvider } from 'react-helmet-async'
import CircularProgress from '@mui/material/CircularProgress'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      style={{ fontSize: '1.5em', textAlign: 'center' }}
      role="alert"
    >
      Error
    </div>
  )
}

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div
          className="w-full h-full"
          style={{ position: 'fixed', backgroundColor: 'gray', zIndex: 800, opacity: '0.7' }}
        >
          <CircularProgress
            size={100}
            color="primary"
            style={{ position: 'fixed', marginLeft: -50, top: '45%', left: '50%', zIndex: 999 }}
          />
        </div>
      }
    >
      {/* 
        React Suspenseとは、コンポーネントがロードされるまでの「待機中」状態を提供する機能になります。
        Suspenseコンポーネントで囲った部分でサスペンドが発生した場合、
        あらかじめ指定してあったフォールバックコンテンツが代わりにレンダリングされます。
        ソースでは、ErrorBoundary以下をレンダリングするが、レンダリングできなかった（サスペンドした）場合は
        fallbackを代わりにレンダリングするという意味になっています。
        ちなみに、サスペンドとはコンピュータの一部の機能を停止して省電力モードで待機させることです。
        https://dev.classmethod.jp/articles/valtio-suspense/
      */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/*
        Error Boundary とは、自身の子コンポーネントツリーで発生した JavaScript のエラーを
        キャッチ・記録しフォールバックの UI を表示するコンポーネントです。
        例えるなら、try/catch構文を行うコンポーネントのようなものと言えます。
        ただし、以下のエラーはキャッチできないので注意
        ・ イベントハンドラ
        ・ 非同期コード（例：setTimeout や requestAnimationFrame のコールバック）
        ・ サーバーサイドレンダリング
          -（子コンポーネントではなく）error boundary 自身がスローしたエラー
        https://zenn.dev/longbridge/articles/b7e76b31f993d9
        */}
        <HelmetProvider>
          {/* 
            QueryClientProviderは、React Queryの機能。 
            React Queryは、データの取得やキャッシュを用いた状態の管理を便利に行える多機能な
            ライブラリです。
            https://qiita.com/uehaj/items/4e41e294181b3771e77a
          */}
          <QueryClientProvider client={queryClient}>
            <Router>{children}</Router>
            {ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={true} />}
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  )
}
