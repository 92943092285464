// 環境タイプ
export const ENV = process.env.REACT_APP_ENV || ''
// リージョン
export const DEFAULT_REGION = process.env.REACT_APP_AWS_DEFAULT_REGION || ''
// Amazon Cognito ユーザプール
export const COGNITO_USER_POOL = process.env.REACT_APP_AWS_COGNITO_USER_POOL || ''
// Amazon Cognito IDプール
export const COGNITO_ID_POOL = process.env.REACT_APP_AWS_COGNITO_ID_POOL || ''
// Amazon Cognito アプリクライアントID
export const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID || ''
// Amazon Cognito リージョン
export const COGNITO_REGION = process.env.REACT_APP_AWS_COGNITO_REGION || DEFAULT_REGION
// Amazon S3 バケット
export const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET || ''
// Amazon S3 バケット
export const DOMAIN = process.env.REACT_APP_DOMAIN || ''
// API URL
export const API_URL = process.env.REACT_APP_API_URL || ''
// Password policy
export const PASSWORD_POLICY = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\\S{10,24}'
// DynamoDB 申し込みテーブル
export const DYNAMODB_APPLICATION_TABLE = process.env.REACT_APP_DYNAMODB_APPLICATION_TABLE || ''
