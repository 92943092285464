import { useRoutes } from 'react-router-dom'
import { getSession } from 'libs/cognito'
import { publicRoutes } from './public'
import { privateRoutes } from './private'

export const AppRoutes = () => {
  const session = getSession()

  const routes = session.isAuth ? privateRoutes : publicRoutes

  const element = useRoutes([...routes])

  return <>{element}</>
}
