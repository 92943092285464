import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'
import { COGNITO_USER_POOL, COGNITO_APP_CLIENT_ID, COGNITO_ID_POOL, DEFAULT_REGION } from 'config'
import { CognitoIdentityProviderClient, ListUsersCommand } from '@aws-sdk/client-cognito-identity-provider'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

export const poolData = {
  UserPoolId: COGNITO_USER_POOL,
  ClientId: COGNITO_APP_CLIENT_ID,
  // Storage: new CookieStorage({ domain: DOMAIN }),
}

export interface Session {
  isAuth: boolean
  idToken: string
  accessToken: string
  refreshToken: string
  email?: string
  givenName?: string
  familyName?: string
  company?: string
}

// Amazon Cognito User Poolのインスタンスを作成
export const userPool = new CognitoUserPool(poolData)

/**
 * CognitoUserインスタンスを生成する関数
 *
 * @param username
 * @returns
 */
export const generateCognitoUser = (username: string) => {
  return new CognitoUser({
    Username: username,
    Pool: userPool,
    // Storage: new CookieStorage({ domain: DOMAIN }),
  })
}

export const getSession = (): Session => {
  const session = {
    isAuth: false,
    idToken: '',
    accessToken: '',
    refreshToken: '',
    familyName: '',
    givenName: '',
    email: '',
    company: '',
  }
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser != null) {
    cognitoUser.getSession((err: any, result: any) => {
      if (result) {
        session.isAuth = true
        session.idToken = result.getIdToken().getJwtToken()
        session.accessToken = result.getAccessToken().getJwtToken()
        session.refreshToken = result.getRefreshToken().getToken()
        session.email = result.getIdToken().payload.email
        session.familyName = result.getIdToken().payload.family_name || ''
        session.givenName = result.getIdToken().payload.given_name || ''
        session.company = result.getIdToken().payload['custom:company'] || ''
      }
    })
  }
  return session
}

export const signOut = async () => {
  return new Promise(() => {
    const cognitoUser = userPool.getCurrentUser()
    if (cognitoUser !== null) {
      cognitoUser.signOut()
    }
  })
}

/**
 * Amazon Cognito
 */
export class Cognito {
  private client: CognitoIdentityProviderClient
  private region: string

  /**
   * コンストラクタ
   *
   * @param region リージョン
   * @param table  テーブル
   */
  constructor(logins: Record<string, string> = {}, region = DEFAULT_REGION) {
    this.region = region
    this.client = new CognitoIdentityProviderClient({
      region: this.region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: this.region }, // Configure the underlying CognitoIdentityClient.
        identityPoolId: COGNITO_ID_POOL,
        logins: logins,
      }),
    })
  }

  /**
   * ユーザ一覧を取得する。
   *
   * @returns
   */
  public async listUsers() {
    const command = new ListUsersCommand({
      UserPoolId: COGNITO_USER_POOL,
      Limit: 60, // 最大60件取得する
    })
    return this.client.send(command)
  }
}
