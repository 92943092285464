import { Suspense } from 'react'
import { signOut, getSession } from 'libs/cognito'
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { lazyImport } from 'utils/lazyImport'
import { AuthenticatedHeader } from 'components/templates/AuthenticatedHeader'
import CircularProgress from '@mui/material/CircularProgress'

const { NotFround } = lazyImport(() => import('components/templates/NotFround'), 'NotFround')
const { PrivateSurveyRoutes } = lazyImport(() => import('features/survey'), 'PrivateSurveyRoutes')

const getMenuItems = () => {
  return [
    {
      label: 'サミットに関する情報',
      subItems: [
        {
          label: '申し込み',
          pathname: '/',
        },
      ],
    },
  ]
}

const Root = () => {
  const navigate = useNavigate()
  const { email } = getSession()
  console.log(email)
  return (
    <>
      <AuthenticatedHeader
        email={`${email}`}
        items={getMenuItems()}
        onSignOut={() => {
          signOut()
          navigate('/auth/sign-in')
        }}
      />
      <Suspense
        fallback={
          <div
            className="w-full h-full"
            style={{ position: 'fixed', backgroundColor: 'gray', zIndex: 800, opacity: '0.7' }}
          >
            <CircularProgress
              size={100}
              color="primary"
              style={{ position: 'fixed', marginLeft: -50, top: '45%', left: '50%', zIndex: 999 }}
            />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  )
}

export const privateRoutes = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to="/app" />,
      },
      {
        path: 'auth/*',
        element: <Navigate to="/app" />,
      },
      {
        path: 'app/*',
        element: <Outlet />,
        children: [
          {
            path: '*',
            element: <PrivateSurveyRoutes />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFround />,
      },
    ],
  },
]
