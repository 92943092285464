import { Suspense } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { lazyImport } from 'utils/lazyImport'
import CircularProgress from '@mui/material/CircularProgress'

const { PublicAuthRoutes } = lazyImport(() => import('features/auth'), 'PublicAuthRoutes')
const { NotFround } = lazyImport(() => import('components/templates/NotFround'), 'NotFround')
const { PublicSurveyRoutes } = lazyImport(() => import('features/survey'), 'PublicSurveyRoutes')

const Root = () => {
  return (
    <>
      <Suspense
        fallback={
          <div
            className="w-full h-full"
            style={{ position: 'fixed', backgroundColor: 'gray', zIndex: 800, opacity: '0.7' }}
          >
            <CircularProgress
              size={100}
              color="primary"
              style={{ position: 'fixed', marginLeft: -50, top: '45%', left: '50%', zIndex: 999 }}
            />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  )
}

export const publicRoutes = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <Navigate to="/auth" />,
      },
      {
        path: 'app/*',
        element: <Navigate to="/auth" />,
      },
      {
        path: 'auth/*',
        element: <PublicAuthRoutes />,
      },
      {
        path: 'public/*',
        element: <Outlet />,
        children: [
          {
            path: '*',
            element: <PublicSurveyRoutes />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFround />,
      },
    ],
  },
]
